module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\stron\\OneDrive\\Documents\\code\\hackcu\\hub"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/hackcu-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd0c787e64d572545f22135c8639d66f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | HackCU","language":"en","openGraph":{"type":"website","locale":"en_US","url":"https://www.hackcu.org/","site_name":"HackCU","title":"HackCU","description":"Join us for HackCU, the largest hackathon at the University of Colorado, Boulder.","images":[{"url":"https://hackcu.org/images/default-seo-image.png","width":800,"height":345,"alt":"Boulder Flatirons"}]},"twitter":{"handle":"@HackCU","site":"@HackCU","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-150},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
