import { Link as GatsbyLink } from 'gatsby';
import React, { HTMLAttributes } from 'react';

export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
	to: string;
	outside?: boolean;
}

const Link: React.FC<LinkProps> = ({ outside = false, to, children, ...props }) => {
	if (!outside) {
		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			<GatsbyLink className="text-secondary-500 font-bold no-underline" {...props} to={to}>
				{children}
			</GatsbyLink>
		);
	}
	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<a
			className="text-secondary-500 font-bold no-underline"
			{...props}
			rel="noreferrer noopener"
			target="_blank"
			href={to}
		>
			{children}
		</a>
	);
};

export default Link;
