import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { PortalManager } from '@chakra-ui/portal';
import components from './mdx';

export const wrapRootElement = ({ element }) => (
	<MDXProvider components={components}>
		<PortalManager>{element}</PortalManager>
	</MDXProvider>
);
